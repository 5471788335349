
import axios from '@/api/newAxiosProxy'


export function esSyn(data) {
    return axios({
        method: 'put',
        url: '/keyWordsSync/updateByBizId',
        data
    })
}