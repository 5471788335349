var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ListFrame',{attrs:{"getPageFn":_vm.getPage,"immediateInit":false,"viewConf":{
    showLvl: true,
    showTitle: true,
    showContent: !/^(cn|en)Intensive$/.test(_vm.selfCondition.contentType),
    showShelStatus: true,
    shelfOperateAble: _vm.operatable
      ? { offshelfFn: _vm.offShelf, onshelfFn: _vm.onShelf }
      : false,
  },"customedParams":_vm.selfCondition},scopedSlots:_vm._u([{key:"conditions",fn:function({ requestFn }){return (_vm.hasCateragy)?[_c('Category',{staticClass:"m-r-md",attrs:{"firstCode":_vm.hbType,"attachAllOpt":true,"secondCode":_vm.selfCondition.category,"thirdCode":_vm.selfCondition.secondCategory},on:{"update:secondCode":[function($event){return _vm.$set(_vm.selfCondition, "category", $event)},requestFn],"update:second-code":function($event){return _vm.$set(_vm.selfCondition, "category", $event)},"update:thirdCode":[function($event){return _vm.$set(_vm.selfCondition, "secondCategory", $event)},requestFn],"update:third-code":function($event){return _vm.$set(_vm.selfCondition, "secondCategory", $event)}}})]:undefined}},{key:"default",fn:function({ item, select }){return _c('PublishedItem',{class:{ 'pointer-title': _vm.operatable },attrs:{"info":item,"editable":_vm.operatable},on:{"clickTitle":function($event){_vm.operatable && select(item)},"showEditTag":function($event){return _vm.showEditTag(item)},"clickPreview":function($event){return _vm.preview(item)}}})}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }