<template>
  <ListFrame
    v-if="loadList"
    :immediateInit="false"
    :getPageFn="getPage"
    :viewConf="{showLvl:true,
                showTitle:true,
                showReset:{onReset:beforeReset},
                itemsDelAble:delAble?{delFn:delBatch}:false,
                selectAble:roleCode===5
               }"
    :customedParams="myParams"
    :auto-refresh="{type:'when-no-select',time:60}"
    keep-status
  >
    <WaitDealItem
      slot-scope="{item}"
      style="font-size: 16px;"
      :isLastView="$route.query.lastId===item.processInstanceId"
      :hb="item"
      @clickView="toViewPage(item)"
    />
    <template slot="batch-opterate" slot-scope="{items,requestFn}">
      <Button
        class="m-l-sm"
        v-if="roleCode===5"
        :disabled="!items.length"
        type="primary"
        @click="clickUploadAudios(items)"
      >上传音频</Button>
      <Drawer title="选择配音" :mask-closable="false" v-model="showAudioDrawer" width="600">
        <UploadAudioBatch
          v-if="showAudioDrawer"
          @audioUploadFinished="onAudioFinished"
          :hbList="items"
        />
      </Drawer>
      <Button
        :disabled="!items.length"
        v-if="roleCode===4"
        @click="submitBatch(items).then(requestFn).catch(()=>{})"
      >提交到配音</Button>
    </template>
    <template #conditions="{requestFn}">
      <FirstCategory      
        v-model="myParams.contentType"
        @input="requestFn"
        class="m-r-md"
        style="width:13em;"
     />
     <ReceiveStatusSelect v-model="myParams.waitStatus" @input="requestFn"
        class="m-r-md"
        style="width:13em;"/>
    </template>
    
  </ListFrame>
  <div v-else></div>
</template>

<script >
import homeService from '../../../api/homePageService';
import WaitDealItem from './WaitDealItem';
import waitDealMixin from '../../waitDealMixin';
export default {
  props: {
    laun: {},
  },

  computed: {
    roleCode() {
      return this.$store.getters.getRoleCode;
    },
    delAble(){
      return [4,5,6,7].includes(this.roleCode)
    },
  },
  components: { WaitDealItem },
  mixins: [waitDealMixin],
  data() {
    return {
      loadList: true,
      myParams: {
        contentType: this.$store.getters.getLaun,
        waitStatus: 2
      }
    };
  },
  methods: {
    beforeReset() {
      this.myParams.contentType = this.$store.getters.getLaun;
    },
    delBatch(list, reason) {
      return homeService.deleteHbBatch(list, reason);
    },
    submitBatch(list) {
      if (list.some((ele) => !/(View)/.test(ele.contentType))) {
        this.$Message.warning('只能批量提交绘本文章');
        return Promise.reject();
      }
      list = list.map((element) => {
        return {
          taskId: element.taskId,
          id: element.bizId,
          processId: element.processInstanceId,
          pass: 0,
          contentType: element.contentType,
          taskName: element.taskName,
          title: element.title
        };
      });
      return homeService.submitBatch(list);
    },
    getPage(params) {
      return homeService.getHbTaskList(params);
    },
    // 跳转获取任务详情
    toViewPage(hb) {
      this.$router.replace({
        path: this.$route.path,
        query: Object.assign({}, this.$route.query, { lastId: hb.processInstanceId })
      });
      let taskId = hb.taskId;
      let bizId = hb.bizId;
      let processId = hb.processInstanceId;
      let contentType = hb.contentType;
      let taskName = hb.taskName;

      /**
       * 如果是配音逻辑，就跳转到配音编辑页面
       *
       */
      if (taskName === 5) {
        this.$router.push({
          path: '/edit4audio',
          query: { taskId, bizId, processId, contentType }
        });
        return;
      }
      if (taskName === 7) {
        this.$router.push({
          name: 'edit4translate',
          query: { taskId, bizId, processId, contentType }
        });
        return;
      }
      if (taskName === 6) {
        this.$router.push({
          path: '/verify6',
          query: { taskId, bizId, processId, contentType }
        });
        return;
      }
      if (taskName === 1 || taskName === 2 || taskName === 3) {
        this.$router.push({
          name: 'verify123',
          query: { taskId, bizId, processId, contentType }
        });
        return;
      }
      if (taskName === 4) {
        this.$router.push({
          name: 'imgPage',
          query: { taskId, bizId, processId, contentType }
        });
      }
    }
  },
  watch: {
    laun() {
      this.loadList = false;
      this.$nextTick(() => {
        this.loadList = true;
      });
    }
  }
};
</script>

<style scoped >
.item-line {
  padding: 20px 20px 0 40px;
  font-size: 16px;
}
/deep/ .conditions-wrapper{
  height: 80px;
}
/deep/ .cond-order{
  flex: 1;
}
/deep/ .cond-order>.ivu-select{
  float:right
}
</style>
