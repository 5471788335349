<template>
  <div class="contains">   
    <div class="top_input">
      <Input
        class="search_input"
        v-model="searchLevel"
        search
        enter-button="查等级"
        placeholder="如：1或1-10"
        @on-search="getWord()"
      />
      <Input
        style="margin-left:20px"
        class="search_input"
        v-model="articleLevel"
        search
        enter-button="查文章等级"
        placeholder="如：1或1-10"
        @on-search="getWord()"
      />
      <Button style="margin-left:20px" type="primary" @click="exportWord">导出文章统计</Button>
      <Upload accept="file" :before-upload="importBtn" action="noAction" class="import-btn">
        <Button style="margin-left:20px" type="primary">导入字库</Button>
      </Upload>
      <Button style="margin-left:20px" type="primary" @click="downloadBtn">下载模板</Button>
    </div>

    <div v-if="wordList&&wordList.length">
      <div class="type_num">
        <div
          class="type_num_chunk"
          :class="{type_num_chunk_other:index==tagSelectedInd}"
          v-for="(item,index) in conf.articles.countTags"
          :key="index"
          @click="tagSelectedInd=index"
        >{{item.label}}</div>
      </div>

      <HomeCmTag
        :countKey="conf.articles.countTags[tagSelectedInd].countKey"
        :site="conf.articles.countTags[tagSelectedInd].site"
        :wordList="wordList"
      >
        <template v-slot:button_chunk="indexLevel" v-if="addable">
          <div class="flex">
            <div
              class="button_chunk"
              v-for="(tp,ind) in conf.articles.contentTypes"
              @click="add(indexLevel, tp)"
              :key="ind"
            >
              <Icon size="24" color="#38A6DD" type="ios-add-circle" />
              <span class="button_chunk_text">{{tp|typePipe('simple')}}</span>
            </div>
          </div>
        </template>
      </HomeCmTag>

      <Page
        :total="totalCount"
        :current="pageNo"
        show-total
        show-sizer
        :page-size-opts="[10]"
        @on-change="getWord"
      />
    </div>
    <div v-else class="page-tip">{{errorMessage}}</div>
  </div>
</template>

<script >
import HomeCmTag from "./HomeCmTag";
import userService from "@/api/userService";
import homeService from "@/api/homePageService";
const formatNYRSF = function (mss) {
  var date = new Date(mss);
  var y = 1900 + date.getYear();
  var m = "0" + (date.getMonth() + 1);
  var d = "0" + date.getDate();
  m = m.substring(m.length - 2, m.length)
  d = d.substring(d.length - 2, d.length)
  // var days = parseInt(mss / (1000 * 60 * 60 * 24));
  // var hours = parseInt((mss % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  var hours = date.getHours();
  var minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60));
  var seconds = (mss % (1000 * 60)) / 1000;
  if (seconds < 10) {
    seconds = "0" + seconds
  } else {
    seconds = "" + seconds
  }
  if (minutes < 10) {
    minutes = "0" + minutes
  } else {
    minutes = "" + minutes
  }
  if (hours < 10) {
    hours = "0" + hours
  } else {
    hours = "" + hours
  }
  seconds = seconds.substring(0, 2)
  return y + "-" + m + "-" + d + "   " + hours + ":" + minutes;
}
function isLvlRight(lvl) {
  if (!lvl) {
    return true;
  }
  let reg = /^\d+(-\d+)?$/;
  if (!reg.test(lvl)) {
    return false;
  }
  return true;
}
export default {
  data() {
    return {
      searchLevel: "",
      articleLevel: "",
      wordList: [],
      tagSelectedInd: 0,
      totalCount: 1,
      pageNo: 1,
      errorMessage: ""
    };
  },
  props: {
    conf: {
      required: true
    },
    addable: {
      default: true
    }
  },
  methods: {
    add(e2, contentType) {
      let contentLevel = this.wordList[e2.indexLevel][0].level;
      let newRoute = this.$router.resolve({
        path: `/edit4Editor/${contentType}`,
        query: { contentLevel }
      });
      let subWin = window.open(newRoute.href, "_blank");
      subWin.onDBModify = () => {
        this.getWord(this.pageNo);
        this.$emit("modify");
      };
    },
    async getWord(pageNo = 1) {
      this.searchLevel = this.searchLevel.trim();
      if (!isLvlRight(this.searchLevel)) {
        this.$Message.warning("请输入正确的等级");
        return;
      }
      this.articleLevel = this.articleLevel.trim();
      if (!isLvlRight(this.articleLevel)) {
        this.$Message.warning("请输入正确的文章等级");
        return;
      }
      this.errorMessage = "";
      let res = await userService.getUserInfo();

      this.wordList = [];
      // allApi 存在bug，请勿在此添加代码！！！

      homeService
        .getWord({
          type: this.conf.typeCode,
          level: this.searchLevel || ((res.levelStart||0) + "-" + (res.levelEnd||9000)),
          articleLevel: this.articleLevel,
          pageNo,
          pageSize: 10
        })
        .then(res => {
          this.pageNo = pageNo;
          if (res) {
            this.wordList = res.dataStore.rows;
            this.totalCount = res.dataStore.totalCount;
          } else {
            this.errorMessage = "没有符合当前默认等级的数据！";
          }
        });
    },
    async exportWord() {
      this.searchLevel = this.searchLevel.trim();
      if (!isLvlRight(this.searchLevel)) {
        this.$Message.warning("请输入正确的等级");
        return;
      }
      this.articleLevel = this.articleLevel.trim();
      if (!isLvlRight(this.articleLevel)) {
        this.$Message.warning("请输入正确的文章等级");
        return;
      }
      let res = await userService.getUserInfo();
      let params = {
        type: this.conf.typeCode,
        level: this.searchLevel || ((res.levelStart||0) + "-" + (res.levelEnd||9000)),
        articleLevel: this.articleLevel,
        pageNo: 1,
        pageSize: 10000
      };
      homeService.exportArticleCount(params);
    },
    importBtn(file) {
      return new Promise((resolve, reject) => {
        let fileType = file.type;
        if (
          fileType.indexOf("application/vnd.ms-excel") < 0 &&
          fileType.indexOf(
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ) < 0
        ) {
          this.$Message.warning("请上传excl");
          reject(false);
        } else {
          let wordType = this.$store.getters.getLaun;
          let formData = new FormData();
          formData.set("file", file);
          formData.set("wordType", wordType);
          console.log(file);
          homeService.wordImportExcel(formData).then(res => {
            if (!res.msg) {
              resolve(file);
              this.$Message.success("上传成功");
              setTimeout(() => {
                this.$router.go(0);
              }, 500);
            } else {
              this.$Message.error(res.msg);
            }
          });
        }
      });
    },
    downloadBtn(fileName) {
      if (!fileName || typeof fileName != "string") {
        let timestamp = new Date().getTime();
        fileName = `中文字库-${formatNYRSF(timestamp)}`;
      }
      let language = this.$store.getters.getLaun; //"cn";
      homeService.wordDownloadExcel({ language }).then(data => {
        let url = window.URL.createObjectURL(data);
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        let timestamp = new Date().getTime();
        link.setAttribute("download", `中文字库-${formatNYRSF(timestamp)}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); //下载完成移除元素
        window.URL.revokeObjectURL(url); //释放掉blob对象
      });
    }
  },
  watch: {
    conf: {
      handler() {
        this.searchLevel = "";
        this.getWord();
      },
      immediate: true
    }
  },
  mounted() {
    userService.OnUserInfoChange = this.getWord;
  },
  beforeDestroy() {
    userService.OnUserInfoChange = null;
  },
  components: { HomeCmTag }
};
</script>

<style scoped >
.search_input {
  width: 30%;
  float: right;
}
.top_input {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  /* justify-content: flex-end; */
  margin: 0 30px 20px 30px;
}

.button_chunk_text {
  margin-left: 5px;
}
.type_num {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  border: 1px solid #000;
  width: min-content;
  margin-left: 30px;
}
.type_num_chunk {
  width: 80px;
  height: 30px;
  text-align: center;
  font-size: 13px;
  line-height: 30px;
  cursor: pointer;
}
.type_num_chunk_other {
  background: rgba(3, 132, 15, 1);
  color: #fff;
}
.button_chunk {
  font-size: 16px;
  color: #38a6dd;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  margin-top: 10px;
  margin-right: 10px;
  cursor: pointer;
}
.import-btn ::v-deep .ivu-upload-list {
  margin-top: 0 !important;
}
</style>
