<template>
  <div v-if="currentConfig">
    <Tabs :value="urlQuery.page" @on-click="changePage" class="centertabs" v-if="!onlyRead">
      <!-- onlyRead用户只能浏览已发布，不可选择 -->
      <!-- 根据不同角色配置不同的选项 -->
      <TabPane
        :index="1"
        :label="currentConfig.articles.tabLabelSite"
        name="home"
        v-if="roleCode===0||roleCode===6"
      />
      <TabPane
        :index="2"
        :label="countObj|countStr('waitDeal','待处理')"
        name="waitDeal"
        v-if="roleCode !==0"
      />
      <TabPane
        :index="3"
        :label="countObj|countStr('submitted',roleCode===6?'提交中':'已提交')"
        name="submitted"
      />
      <TabPane :index="4" :label="countObj|countStr('publish','已发布')" name="published" />
      <TabPane
        :index="5"
        :label="countObj|countStr('draft','草稿')"
        name="draft"
        v-if="roleCode===0"
      />
      <TabPane
        :index="6"
        :label="countObj|countStr('disused','回收站')"
        name="recycle"
        v-if="roleCode===0||roleCode===6"
      />
    </Tabs>
    <HomeContent
      v-if="urlQuery.page==='home'"
      :addable="roleCode===0"
      :conf="currentConfig"
      @modify="initData"
    />
    <PublishedSubPage
      v-if="urlQuery.page==='published'"
      :contentTypes="currentConfig.articles.contentTypes"
    />
    <WaitDealContent v-if="urlQuery.page==='waitDeal'" :laun="laun" />
    <DraftContent v-if="urlQuery.page==='draft'" :laun="laun" @modify="initData" />
    <RecycleList v-if="urlQuery.page==='recycle'" :laun="laun" :recycleable="roleCode===0" />
    <SubmittedContent v-if="urlQuery.page==='submitted'" :laun="laun" />
  </div>
  <div v-else></div>
</template>

<script >
import homeService from "@/api/homePageService";
import getConfig from "./getTagConfig";
import HomeContent from "./articles/HomeContent";
import WaitDealContent from "./articles/waitdeal/WaitDealContent";
import DraftContent from "./articles/DraftContent";
import PublishedSubPage from "./articles/published/PublishedSubPage";
import SubmittedContent from "./articles/SubmittedContent";
import RecycleList from "./articles/RecycleList";
let countObjCache;
export default {
  filters: {
    countStr(obj, key, label) {
      obj = obj || countObjCache;
      let { totalCount, totalLevel } = (obj && obj[key]) || {};
      let str = label;
      let lvl, cnt;
      if (undefined !== totalLevel) {
        lvl = totalLevel + "级";
      }
      if (undefined !== totalCount) {
        cnt = totalCount + "篇";
      }
      if (lvl || cnt) {
        return str + "(共" + (lvl || "") + (cnt || "") + ")";
      }
      return str;
    }
  },
  components: {
    WaitDealContent,
    DraftContent,
    HomeContent,
    PublishedSubPage,
    SubmittedContent,
    RecycleList
  },
  data() {
    return {
      countObj: null,
      urlQuery: {},
      currentConfig: null
    };
  },
  computed: {
    roleCode() {
      return this.$store.getters.getRoleCode;
    },
    laun() {
      return this.$store.getters.getLaun;
    },
    onlyRead() {
      return this.$store.getters.getOnlyRead;
    }
  },
  methods: {
    initFromRouter() {
      this.urlQuery = this.$route.query;
      if (this.onlyRead && this.urlQuery.page !== "published") {
        // onlyRead 只能看已发布列表
        this.urlQuery = { page: "published" };
        this.refresh();
        return;
      }
      
      if (!this.urlQuery.page) {
        this.urlQuery = {
          page: this.roleCode === 0 ? "home" : "waitDeal"
        };
        this.refresh();
      }
    },
    refresh() {
      this.$router.replace({
        name: this.$route.name,
        query: this.urlQuery
      });
    },
    changePage(page) {
      if (page === this.urlQuery.page) {
        return;
      }
      this.urlQuery = { page };
      this.refresh();
    },
    initData() {
      homeService.getTaskCount(this.laun).then(res => {
        countObjCache = this.countObj = res;
      });
    }
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.initFromRouter();
  },
  mounted() {
    this.initFromRouter();
  },
  watch: {
    laun: {
      handler() {
        if (!this.laun) {
          return;
        }
        this.currentConfig = getConfig(this.laun);
        this.initData();
      },
      immediate: true
    }
  }
};
</script>

<style scoped >
</style>
