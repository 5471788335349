<template>
  <div>
    <ListFrame
      :immediateInit="false"
      :getPageFn="getPage"
      :viewConf="{showLvl:true,showTitle:true}"
      :customedParams="myParams"
    >
      <template slot-scope="{item}">
        <div style="font-size: 18px;">
          <p
            style="padding-bottom: .4em;"
            v-if="!(/Verse/.test(item.contentType))"
          >主标题：{{item.title}}</p>
          <span v-if="/Verse/.test(item.contentType)" class="m-r-md">文章标题：{{item.title}}</span>
          <span v-else class="m-r-md">文章标题：{{item.contentTitle&&item.contentTitle[0]}}</span>
          <span class="m-r-md" style="opacity: .5;font-size: 16px;">{{item.contentType|typePipe}}</span>
        </div>
        <p class="text-mute flex" style="margin-top: 6px;font-size: 16px;color: #9d9d9d;">
          <span class="m-r-md">
            提交时间：
            <Time v-if="item.subTime" :time="item.subTime" type="datetime" />
          </span>
          <span class="m-r-md">等级：{{item.contentLevel}}</span>
          <span v-if="item.nowUser">当前处理人：{{item.nowUser}}</span>
        </p>
        <div style="margin-bottom: 6px;font-size: 14px;">
          <div v-if="[0,1,2,3].includes(roleCode)">
            编辑标签：
            <TagsInput v-if="item.tagMap" :tags="item.tagMap[0]||[]" :editable="false" />
          </div>
          <div v-else-if="roleCode===4">
            插画标签：
            <TagsInput v-if="item.imageTag" :tags="item.imageTag[0]||[]" :editable="false" />
          </div>
          <div v-else-if="roleCode===5">
            音频标签：
            <TagsInput v-if="item.audioTag" :tags="item.audioTag[0]||[]" :editable="false" />
          </div>
        </div>
        <ProcessSteps
          style="margin: 6px 0;font-size: 16px;width: 700px;"
          :taskName="item.nowTaskName"
        />
        <Button type="warning" style="width:6em;font-size: 16px;" @click="previewTask(item)">查看</Button>
      </template>
      <template slot="conditions" slot-scope="{ requestFn }">
        <FirstCategory
          v-model="myParams.contentType"
          @input="requestFn"
          class="fl m-r-md"
          style="width:13em;"
        />
      </template>
    </ListFrame>
    <Drawer :title="taskInfo.title||'加载中...'" width="30" v-model="showPreview">
      <HbPreviewDrawer v-if="taskInfo.title" :hb="taskInfo" @clickSubmit="updateTabs" />
    </Drawer>
  </div>
</template>

<script>
import homeService from '@/api/homePageService';
import { getSubmitedInfo } from '@/api/hbService';
export default {
  props: {
    laun: {
      required: true
    },
  },
  data() {
    return {
      showPreview: false,
      taskInfo: {},
      prviewingItem: null,
      myParams: {
        contentType: this.$store.getters.getLaun
      }
    };
  },
  computed:{
    roleCode() {
      return this.$store.getters.getRoleCode;
    },
  },
  methods: {
    getPage(params) {
      return homeService.getSubmitedList(params);
    },
    previewTask(item) {
      this.prviewingItem = item;
      this.taskInfo = {};
      this.showPreview = true;
      getSubmitedInfo({
        bizId: item.bizId,
        taskName: this.roleCode,
        contentType: item.contentType
      }).then((res) => {
        this.taskInfo = res.content;
      });
    },
    updateTabs(newItem) {
      homeService.updateHb(newItem).then((res) => {
        this.prviewingItem.tagMap = newItem.tagMap;
        this.prviewingItem.imageTag = newItem.imageTag;
        this.prviewingItem.audioTag = newItem.audioTag;
        this.showPreview = false;
      });
    }
  }
};
</script>

<style>
</style>