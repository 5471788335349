<template>
  <ListFrame
    v-if="showList"
    :getPageFn="getPageFn"
    :immediateInit="false"
    :customedParams="customedParams"
    :viewConf="{showReorder:false}"
  >
    <template #conditions="{requestFn}">
      <FirstCategory
        v-model="customedParams.contentType"
        @input="requestFn()"
        :hiddenWhole="true"
        class="typeSelect"
      />
      <Button @input="requestFn()" type="primary" class="m-l-sm">查询</Button>
    </template>

    <template slot-scope="{item,requestFn}">
      <div class="flex-middle">
        <p class="text-ellips" :title="item.title">{{item.title}}</p>
        <p class="text-ellips">创建时间:{{item.createTime}}</p>

        <Button type="primary" class="button m-l-sm" @click="toEditPage(item,requestFn)">继续编辑</Button>
        <Poptip confirm title="确定要删除吗？？？" @on-ok="deleteDraft(item).then(requestFn)">
          <Button type="error" class="button m-l-sm">删除</Button>
        </Poptip>
      </div>
    </template>
  </ListFrame>
  <div v-else></div>
</template>

<script >
import axios from '@/api/newAxiosProxy';
export default {
  props: {
    laun: {}
  },
  data() {
    return {
      customedParams: { contentType: '' },
      showList: true
    };
  },
  watch: {
    laun: {
      handler() {
        this.customedParams.contentType = this.laun + 'View';
        this.showList = false;
        this.$nextTick(() => {
          this.showList = true;
        });
      },
      immediate: true
    }
  },
  methods: {
    getPageFn(params) {
      return axios({
        method: 'get',
        url: '/common/content/draftList',
        params
      });
    },

    deleteDraft(item) {
      return axios({
        url: '/common/content/delete',
        method: 'delete',
        data: { id: item.id, contentType: item.contentType }
      }).then(() => {
        this.$Message.success('删除成功！');
        this.$emit('modify');
      });
    },
    toEditPage(task, fn) {
      let newRoute = this.$router.resolve({
        path: `/edit4Editor/${task.contentType}`,
        query: { bizId: task.id }
      });
      let subWin = window.open(newRoute.href, task.id);
      subWin.onDBModify = () => {
        typeof fn === 'function' && fn();
        this.$emit('modify');
      };
    }
  }
};
</script>

<style scoped  >
.button {
  width: 100px;
}
.title {
  font-weight: bold;
  font-size: 20px;
}

.text-ellips {
  width: 250px;
  overflow: hidden; /*超出部分隐藏*/
  text-overflow: ellipsis; /* 超出部分显示省略号 */
}

.typeSelect {
  width: 140px;
}
</style>
