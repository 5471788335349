<template>
  <ListFrame
    :getPageFn="getPage"
    :immediateInit="false"
    :viewConf="{
      showLvl: true,
      showTitle: true,
      showContent: !/^(cn|en)Intensive$/.test(selfCondition.contentType),
      showShelStatus: true,
      shelfOperateAble: operatable
        ? { offshelfFn: offShelf, onshelfFn: onShelf }
        : false,
    }"
    :customedParams="selfCondition"
  >
    <template slot="conditions" slot-scope="{ requestFn }" v-if="hasCateragy">
      <Category
        class="m-r-md"
        :firstCode="hbType"
        :attachAllOpt="true"
        :secondCode.sync="selfCondition.category"
        :thirdCode.sync="selfCondition.secondCategory"
        @update:secondCode="requestFn"
        @update:thirdCode="requestFn"
      />
    </template>

    <PublishedItem
      slot-scope="{ item, select }"
      :class="{ 'pointer-title': operatable }"
      :info="item"
      :editable="operatable"
      @clickTitle="operatable && select(item)"
      @showEditTag="showEditTag(item)"
      @clickPreview="preview(item)"
    />
  </ListFrame>
</template>

<script >
import PublishedItem from "./PublishedItem";
import homeService from "@/api/homePageService";
export default {
  props: {
    hbType: {
      default: "cnView",
    },
    hasCateragy: {
      default: false,
    },
    operatable: {
      default: true,
    }
  },
  data() {
    return {
      initMaxLevel: false,
      selfCondition: { contentType: "", category: "", secondCategory: "" },
    };
  },
  methods: {
    showEditTag(item) {
      this.$emit("showEditTag", item);
    },
    onShelf(list) {
      return homeService.onShelf(list);
    },
    offShelf(list, reason) {
      return homeService.offShelf(list, reason);
    },
    getPage(params) {
      return homeService.getPublishList(params, false).then((res) => {
        // if (!this.initMaxLevel) {
        this.initMaxLevel = true;
        this.$emit("initMaxLevel", this.hbType, +res.maxLevel || 0);
        // }
        return res.list;
      });
    },
    preview(info) {
      this.$emit("preview", info);
    },
    onBeforeReset() {
      this.selfCondition = {
        contentType: this.hbType,
        category: "",
        secondCategory: "",
      };
    },
  },
  beforeMount() {
    this.selfCondition.contentType = this.hbType;
  },
  components: { PublishedItem },
};
</script>

<style scoped >
.pointer-title ::v-deep .title {
  cursor: pointer;
}
</style>
