<template>
  <div>
    <Tabs :value="subpage" @on-click="changeSubpage">
      <TabPane
        v-for="type in contentTypes"
        :key="type"
        :label="type | typePipe('simple','（共' + maxLevelMap[type] + '级)')"
        :name="type"
      >
        <!--  v-for="type in contentTypes.filter(ele=>maxLevelMap[ele]!==0)"  不再隐藏，这个和后台接口有关 -->
        <PublishedPane
          v-if="type!=='lzBook'"
          :hasCateragy="/view/i.test(type)"
          :hbType="type"
          :operatable="!onlyRead"
          @initMaxLevel="initMaxLevel"
          @preview="preview"
          @showEditTag="showEditTag"
        />
        <SerialBook 
          ref="serialBook"
          :showSerialItemFlag="showSerialItemFlag"
          @changeShowSerial="changeShowSerial"
          v-else />
      </TabPane>
    </Tabs>

    <Drawer :title="hb.title||'加载中...'" width="30" v-model="showTabsEditor">
      <HbPreviewDrawer
        v-if="hb.title"
        :hb="hb"
        @clickSubmit="updateTabs"
        :hidde-tags="roleCode!==6"
      />
    </Drawer>
  </div>
  <!-- <div v-else class="page-tip">当前没有已发布内容</div> -->
</template> 

<script>
import { getHbTaskView, hbAdapt, getSubmitedInfo } from '@/api/hbService';
import homePageService from '@/api/homePageService';
import PublishedPane from './PublishedPane';
import SerialBook from './SerialBook';

export default {
  components: { PublishedPane,SerialBook },
  props: {
    contentTypes: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      maxLevelMap: {},
      showTabsEditor: false,
      hb: {},
      editingTagsItem: null,
      subpage: '',
      showSerialItemFlag: false
    };
  },
  mounted() {
    // console.log("contentTypes:",);
    if(this.roleCode==6 && this.contentTypes.indexOf("lzBook")==-1){
      this.contentTypes.push('lzBook');
    }
  },
  computed:{
    roleCode() {
      return this.$store.getters.getRoleCode;
    },
    onlyRead() {
      return this.$store.getters.getOnlyRead;
    }
  },
  methods: {
    initMaxLevel(type, level) {
      this.$set(this.maxLevelMap, type, level);
      this.$forceUpdate();
      if (this.subpage) {
        return;
      }
      if (Object.values(this.maxLevelMap).length === this.contentTypes.length) {
        for (let type of this.contentTypes) {
          if (this.maxLevelMap[type]) {
            this.subpage = type;
            break;
          }
        }
      }
    },
    changeSubpage(name) {
      this.$router.replace({
        path: this.$route.path,
        query: Object.assign({ ...this.$route.query }, { publishedPage: name })
      });
      this.subpage = name;
      console.log("clickName:",name);
      if(name=="lzBook"){
        this.showSerialItemFlag = false;
         this.$refs.serialBook[0].refresh();
      }
    },
    changeShowSerial(){
      this.showSerialItemFlag = !this.showSerialItemFlag
    },
    preview(info) {
      getHbTaskView({ bizId: info.id, contentType: info.contentType }).then((res) => {        
        this.hb = res.content;        
        this.$mobilePreview(this.hb)
      });
    },
    showEditTag(info) {
      this.editingTagsItem = info;
      getHbTaskView({ bizId: info.id, contentType: info.contentType }).then((res) => {
        let hb = res.content;
        hb.taskName = this.roleCode;
        hb = hbAdapt(hb);
        this.hb = hb;
        this.showTabsEditor = true;
      });
    },
    updateTabs(newInfo) {
      homePageService.updateHb(newInfo).then((res) => {
        this.editingTagsItem.tagMap = newInfo.tagMap;
        this.editingTagsItem.imageTag = newInfo.imageTag;
        this.editingTagsItem.audioTag = newInfo.audioTag;
        this.showTabsEditor = false;
      });
    },
  },
  activated() {
    this.subpage = this.$route.query.publishedPage;
    if (this.subpage) {
      return;
    }
    // 尝试获取第一个存在数据的类型，作为要显示的页面
    for (let type of this.contentTypes) {
      if (this.maxLevelMap[type]) {
        this.subpage = type;
        break;
      }
    }
    if (!this.subpage) {
      // 尝试失败，说明是首次加载或切换了laun等，需要清理数据：
      this.maxLevelMap = {};
    }
  }
};
</script>

<style scoped>
</style>