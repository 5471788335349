export default function (laun = "en") {
    let config = {
        en: {
            typeWord: "en",
            typeName: "英文",
            typeCode: 2,
            articles: {
                countTags: [  // 首页的统计配置：
                    { label: "字总篇数", countKey: "countArticle", site: "篇" },
                    { label: "词卡篇数", countKey: "en_words_article_count", site: "篇" },
                    { label: "句子篇数", countKey: "en_sentence_article_count", site: "篇" },
                    { label: "韵文篇数", countKey: "en_verse_article_count", site: "篇" },
                    { label: "绘本篇数", countKey: "en_view_article_count", site: "篇" },
                    { label: "i 绘本", countKey: "en_ieng_article_count", site: "篇" },
                    { label: "引用次数", countKey: "count", site: "字" },
                    { label: "本级次数", countKey: "countSelfClazz", site: "字" }
                ],
                contentTypes: ["enSentence", "enVerse", "enView", "enWords"],  // 包含的类型
                tabLabelSite: "词库"  // 单位名称
            }
        },
        cn: {
            typeWord: "cn",
            typeName: "中文",
            typeCode: 1,
            articles: {
                countTags: [
                    { label: "字总篇数", countKey: "countArticle", site: "篇" },
                    { label: "词语篇数", countKey: "cn_words_article_count", site: "篇" },
                    { label: "成语篇数", countKey: "cn_idiom_article_count", site: "篇" },
                    { label: "绘本篇数", countKey: "cn_view_article_count", site: "篇" },
                    { label: "韵文篇数", countKey: "cn_verse_article_count", site: "篇" },
                    { label: "引用次数", countKey: "count", site: "字" },
                    { label: "本级次数", countKey: "countSelfClazz", site: "字" }
                ],
                contentTypes: ["cnWords", "cnView", "cnVerse","cnIntensive"], // 去掉了 cnIdiom 
                tabLabelSite: "字库"
            }                                                 
        }
    }    
    return config[laun]
}