<template>
  <div class="">
    <div class="serial-top">
      <span class="serial-title">连载书籍名称：{{ choiceSerial.name }}</span>
      <Button @click="showModelFlag" style="width: 6em" class="edit-btn" type="primary"
        >新增</Button
      >
    </div>
    <p v-if="searchList.length<=0" style="font-siez:16px;">暂无章节请新增</p>
    <transition-group name="sort" tag="div">
      <div class="book-list-item flex" v-for="(item, i) in searchList" :key="item.bizId">
        <div class="flex book-list-btn" v-if="searchList.length>1">
          <button
            v-if="i > 0"
            class="txt-btn text-primary"
            @click="move(searchList, i - 1, i)"
          >
            <Icon type="md-arrow-round-up" />
          </button>
          <button
            v-if="i < searchList.length - 1"
            class="txt-btn text-primary"
            @click="move(searchList, i, i + 1)"
          >
            <Icon type="md-arrow-round-down" />
          </button>
        </div>
        <div>
          <p class="book-list flex">
            <span class="turn-title" :title="item.contentTitleList">{{ turnTitle(item.contentTitleList) }}</span>
            <span style="margin-left: 20px">等级：{{ item.contentLevel }}</span>
          </p>
          <div>
            <Button type="primary" style="width: 5em" @click="previewBook(item)">预览</Button>
            <Button style="margin-left: 1em; width: 5em" type="error" @click="delBook(searchList,i)"
              >删除</Button
            >
          </div>
        </div>
      </div>
    </transition-group>
    <Modal v-model="modalFlag" width="600">
      <p slot="header"></p>
      <div style="text-align: center">
        <div>
          <Input
            placeholder="请输入标题"
            class="m-r-md cond-content"
            style="width: 15em"
            v-model="searchBook.title"
            @on-enter="InSearchBookClick"
          ></Input>
          <Button type="primary" style="width: 6em" @click="InSearchBookClick">查询</Button>
        </div>
        <div class="check-books-max">
          <p class="noData-Title" v-if="noDataTitle">{{noDataTitle}}</p>
          <div class="check-books" v-for="(item) in searchResList"
            :key="item.id">
            <Checkbox :disabled="item.seriesName&&item.seriesName!==null" class="check-books-item" @on-change="choiceBox($event,item)">
              <span class="item-book">{{ item.title }}</span>
              <span :class="{'dark':item.seriesName&&item.seriesName!==null}" style="margin-left:20px;">等级：{{ item.contentLevel }}</span>
              <span v-if="item.seriesName&&item.seriesName!==null" class="dark" style="margin-left:20px;max-width:250px;">已在连载书籍《{{item.seriesName}}》中</span>
            </Checkbox>
          </div>
        </div>
      </div>
      <div class="addSerial-book" slot="footer">
        <Button type="primary" @click="sureAdd">确认添加</Button>
      </div>
      <Spin size="large" fix v-if="spinShow"></Spin>
      <Page
        class="book-item-page"
        v-if="totalCount>10"
        :total="totalCount"
        show-total
        show-sizer
        :current="searchPageNo"
        :page-size="searchPageSize"
        :page-size-opts="pageSizeList" 
        @on-change="changePage"
        @on-page-size-change="changePageSize"
      />
    </Modal>
  </div>
</template>
<script>
import homeService from "@/api/homePageService";
import { getHbTaskView, hbAdapt, getSubmitedInfo } from "@/api/hbService";
export default {
  props: {
    choiceSerial: {
      type: Object,
    },
  },
  data() {
    return {
      hb: {},
      modalFlag: false,
      checkedBooks: [],
      searchList: [],
      searchResList:[],
      timer:null,
      searchPageSize:10,
      searchPageNo:1,
      searchBook:{
        title:"",
        subject:this.$store.getters.getLaun
      },
      totalCount:0,
      totalPage:0,
      pageSizeList:[10,20,50],
      spinShow:false,
      noDataTitle:''
    };
  },
  created() {
  },
  computed:{
    
  },
  mounted() {
    this.initPage(true);
  },
  watch:{
    choiceSerial:{
      handler(val){
        console.log("choiceSerial:",val);
      },
      immediate:true
    }
  },
  methods: {
    InSearchBookClick(){
      this.searchPageSize = 10;
      this.searchPageNo = 1;
      if(!this.searchBook.title){
        this.$Message.warning('请输入标题查询');
        return
      }
      this.searchBookClick();
      
    },
    changePage(val){
      this.searchPageNo = val;
      this.searchBookClick();
    },
    changePageSize(pageSizeVal){
      this.searchPageSize = pageSizeVal;
      this.searchBookClick();
    },
    turnTitle(val){
      return val[0];
    },
    showModelFlag(){
      this.modalFlag = true;
      this.searchBook.title = '';
      this.searchPageSize = 10;
      this.searchPageNo = 1;
      this.searchResList = [];
      this.totalCount = 0;
      this.totalPage = 0;
      // this.searchBookClick();
    },
    checkGroupChange (val) {
      console.log("data:",val);
    },
    choiceBox(val,item){
      console.log("val:",val);
      
      if(val){
        this.checkedBooks.push(item);
      } else {
        this.checkedBooks.map((itemNei,index)=>{
          if(itemNei.bizId==item.bizId){
            this.checkedBooks.splice(index,1);
          }
        })
      }
      // let obj = {};
      // let peon = this.checkedBooks.reduce((cur,next) => {
      //   obj[next.bizId] ? "" : obj[next.bizId] = true && cur.push(next);
      //   return cur;
      // },[])
      // this.checkedBooks = peon;
      console.log("checkedBooks:",this.checkedBooks);
    },
    searchBookClick(){
      this.spinShow = true;
      this.searchResList = [];
      homeService.querySeriesContent(this.searchBook,this.searchPageSize,this.searchPageNo).then((res) => {
        if(res){
          this.spinShow = false;
          this.searchResList = res.rows;
          this.totalCount = res.totalCount;
          this.totalPage = res.totalPage;
          if(res.rows==null || !res.rows){
            this.noDataTitle = "该关键词没有数据，请输入其他关键词重新搜索"
          } else {
             this.noDataTitle = ""
          }
        } else{
          this.spinShow = false;
          this.totalCount = 0;
          this.totalPage = 0;
          this.searchResList = [];
           this.noDataTitle = ""
        }
      }).catch((err) => {
        this.spinShow = false;
        this.totalCount = 0;
        this.totalPage = 0;
        this.$Message.warning(err);
        this.errorList = true;
        throw err;
      })
   
    },
    initPage(showSpin) {
      let params = {
        id: this.choiceSerial.id,
        name:this.choiceSerial.name,
        levels:this.choiceSerial.levels,
        pageSize:100,
        pageNo:1
      }
      if(showSpin){
        this.$Spin.show();
      }
      homeService.querySeriesBook(params,false).then((res) => {
        if(res.rows.length>0){
          this.searchList = res.rows[0].materialOrderList;
          console.log("this.searchList:",this.searchList); 
          this.$forceUpdate();
          if(showSpin){
            this.$Spin.hide();
          }
        } else{
          this.$Spin.hide();
        }
      }).catch((err) => {
        this.$Spin.hide();
        this.$Message.warning(err);
        throw err;
      })
    },
    updataBook(addList,flag){
      if(addList.length>0){
        addList.map((item,index)=>{
          item.orderNum = index+1
        })
      }
      let params = {
        id: this.choiceSerial.id,
        levels: this.choiceSerial.level,
        materialOrderList: addList,
        name: this.choiceSerial.name,
        subject: this.choiceSerial.subject
      }
      homeService.updateSeriesBook(params).then((res) => {
        console.log("res:",res);  
        this.initPage(flag);
      }).catch(()=>{
        this.initPage(flag);
      })
      this.checkedBooks = [];
    },
    move(list, i, j) {
      if (i < 0 || j < 0 || i >= list.length || j >= list.length) {
        return;
      }
      if (i > j) {
        let t = i;
        i = j;
        j = t;
      }
      let itemj = list.splice(j, 1)[0];
      list.splice(i, 0, itemj);
      //防抖处理
      if(this.timer){
          clearTimeout(this.timer)
          this.timer=null
      }
      // console.log("list:",list);
      this.timer=setTimeout(()=>{
        // let sortChanges = [];
        // list.forEach((ele, i) => {
        //     if (ele.sort === i) {
        //         return;
        //     }
        //     ele.sort = i;
        //     sortChanges.push(ele);
        // });
        this.updataBook(list,false);
      },1500)
    },
    sureAdd() {
      console.log("choiceArray:", this.checkedBooks);
      console.log("searchList:", this.searchList);
      let addList = [...this.searchList,...this.checkedBooks];
      let obj = {};
      let peon = addList.reduce((cur,next) => {
        obj[next.bizId] ? "" : obj[next.bizId] = true && cur.push(next);
        return cur;
      },[])
      addList = peon;
      this.updataBook(addList,true);
      this.modalFlag = false;
    },
    previewBook(item) {
      getHbTaskView({ bizId: item.bizId, contentType: item.contentType }).then(
        (res) => {
          this.hb = res.content;
          this.$mobilePreview(this.hb);
        }
      );
    },
    delBook(list,i) {
      this.$Modal.confirm({
          title: '是否删除该章节？',
          content: '<p>确认需要删除请点击确认，否则取消。</p>',
          okText: '确认',
          cancelText: '取消',
          onOk: () => {
            this.delteBook(list,i);
          },
      });
    },
    delteBook(list,i){
      list.splice(i, 1);
      this.updataBook(list,false);
    }
  },
};
</script>
<style lang="scss" scoped>
.book-item-page { 
  margin: 10px auto;
}
.turn-title {
  max-width: 300px;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
.noData-Title {
  font-size: 16px;
  margin: 10px 0;
}
.check-books-max{
  overflow: auto;
  max-height: 600px;
  min-height: 50px;
}
.item-book {
  // overflow: hidden;
  // text-overflow:ellipsis;
  // white-space: nowrap;
  max-width: 200px;
  margin-left: 10px;
}
.text-primary {
  font-size: 20px;
}
.sort-move {
  transition: transform 0.5s;
}
.book-list-item {
  margin-bottom: 20px;
  .book-list-btn{
    width: 45px;
  }
}
.book-list {
  font-size: 16px;
}
.check-books {
  text-align: left;
  // padding: 20px;
  // max-height: 300px;
  // overflow-y: scroll;
  margin-top: 8px;
  .check-books-item {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    .dark {
      color: #ccc;
    }
  }
}
.addSerial-book {
  display: flex;
  align-items: center;
  justify-content: center;
}
.serial-title {
  font-size: 24px;
  color: #333333;
  margin-right: 20px;
}
.serial-top {
  display: flex;
  // justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
</style>
