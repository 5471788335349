<!--
 * @Author: hejinsheng
 * @Date: 2022-06-16 18:24:21
 * @LastEditors: hejinsheng
 * @LastEditTime: 2022-06-17 17:33:12
 * @FilePath: \lshy-manage\src\page-list\articles\published\OrganizationForm.vue
-->

<template>
  <div>
    <Modal v-model="model" :loading="loading" :title="info.title" @on-ok="ok" @on-cancel="cancel">
      <Form :model="formItem" :label-width="80">
        <FormItem label="机构ID">
          <Input v-model="formItem.orgIds" placeholder="请输入机构ID，多个用小写逗号隔开。"></Input>
        </FormItem>

        <FormItem label="作业布置">
          <i-switch v-model="formItem.workStatus" size="large">
            <template #open>
              <span>是</span>
            </template>
            <template #close>
              <span>否</span>
            </template>
          </i-switch>
        </FormItem>
      </Form>
    </Modal>
  </div>
</template>

<script>
import homePageService from "@/api/homePageService";
export default {
  props: ["info", "modelShow"],
  data() {
    return {
      model: false,
      loading: true,
      formItem: {
        orgIds: this.info.orgIds,
        workStatus: this.info.workStatus ? true : false,
      },
    };
  },
  methods: {
    checkOrgIdsNumber(orgIds) {
      var numReg = /^[1-9][0-9]*$/;
      var numRe = new RegExp(numReg);

      let orgArr = orgIds.split(",");
      console.log(orgArr.length);
      for (const index in orgArr) {
        console.log(orgArr[index], !numRe.test(orgArr[index]), 1111);
        if (
          !numRe.test(orgArr[index]) ||
          orgArr[index] == "" ||
          orgArr[index] == 0
        ) {
          return true;
        }
      }

      return this.isRepeat(orgArr);
    },
    isRepeat(v) {

      let obj = {}
      for (let i in v) {
        if (obj[v[i]]) {
          return true
        }
        obj[v[i]] = true
      }
      return false
    },
    messageWarningFn(text) {
      this.$Message.warning(text);
      setTimeout(() => {
        this.loading = false;
        this.$nextTick(() => {
          this.loading = true;
        });
      }, 500);
    },
    ok() {
      let formItem = this.formItem;

      if (formItem.orgIds && this.checkOrgIdsNumber(formItem.orgIds)) {
        this.messageWarningFn("请检查机构id");
        return;
      }

      this.info.orgIds = formItem.orgIds;
      this.info.workStatus = formItem.workStatus;

      homePageService.updateHb(this.info).then((res) => {
        console.log(res);
      });

      this.$emit("modelShowClose");
    },
    cancel() {
      this.$emit("modelShowClose");
    },
  },
  watch: {
    modelShow: {
      deep: true, // 深度监听
      handler(newVal, oldVal) {
        this.model = newVal;
        this.formItem.orgIds = this.info.orgIds;
      },
    },
  },
};
</script>
