<template>
  <div>
    <div style="font-size: 18px;">
      <p style="padding-bottom: .3em;" v-if="!(/韵文/.test(hb.category))">主标题：{{hb.title}}</p>
      <span v-if="/韵文/.test(hb.category)" class="m-r-md">文章标题：{{hb.title}}</span>
      <span v-else class="m-r-md">文章标题：{{hb.contentTitle&&hb.contentTitle[articInd]}}</span>
      <span style="opacity: .5;font-size: 16px;">{{hb.category}}</span>
    </div>
    <div style="display:flex">
      本文篇数：
      <EditorSider
        style="font-size:8px;"
        :horizontal="true"
        :modifiable="false"
        :activeIndex.sync="articInd"
        :len="hb.contentTitle && hb.contentTitle.length || 1"
      />
    </div>
    <div v-if="roleCode <= 6">
      编辑标签：
      <TagsInput v-if="hb.tagMap" size="default" :tags="hb.tagMap[articInd]||[]" :editable="false" />
    </div>
    <div v-if="[4,5,6].includes(roleCode)">
      插画标签：
      <TagsInput
        v-if="hb.imageTag"
        size="default"
        :tags="hb.imageTag[articInd]||[]"
        :editable="false"
      />
    </div>
    <div v-if="[5,6].includes(roleCode)">
      音频标签：
      <TagsInput
        v-if="hb.audioTag"
        size="default"
        :tags="hb.audioTag[articInd]||[]"
        :editable="false"
      />
    </div>
    <p class="text-mute flex" style="margin-top: 6px;font-size: 16px;">
      <span class="m-r-md" v-if="hb.createTime">创建时间： <Time :time="hb.createTime" type="datetime" /></span>
      <span class="m-r-md" v-if="hb.modifyTime">修改时间：{{hb.modifyTime}}</span>
      <span>当前等级：{{hb.contentLevel}}</span>
    </p>
    <ProcessSteps style="margin: 6px 0;font-size: 16px;width: 700px;" :taskName="hb.taskName" />    
    <div style="display: flex;align-items: center;">
      <ButtonsForWaitdealList :hb="hb" :isLastView="isLastView" @to-handle="toViewPage"/>
      <div class="text-error" v-if="hb.clip && hb.taskName === 5">音频匹配失败：部分匹配失败，请点击查看进入修改</div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    hb: { required: true },
    isLastView: {
      default: false
    }
  },
  data() {
    return {
      articInd: 0
    };
  },
  computed:{       
    roleCode() {
      return this.$store.getters.getRoleCode;
    },
  },
  methods: {
    toViewPage() {
      this.$emit("clickView");
    }, 
    
  }
};
</script>

<style scoped>
</style>