<template>
  <ListFrame
    :immediateInit="false"
    :getPageFn="getPageFn"
    :customedParams="customedParams"
    :viewConf="{showLvl:true,showTitle:true}"
  >
    <FirstCategory
      slot="conditions"
      slot-scope="{requestFn}"
      v-model="customedParams.contentType"
      @input="requestFn()"
      style="width: 200px;  margin: 0 20px;"
    />
    <template slot-scope="{item,requestFn}">
      <div style="font-size:1.2em;font-weight:bold;">
        {{item.title}}
        <br />
        {{item.titleObject}}
      </div>
      <div style="margin:.75em 0 .25em;display:flex">
        <div>
          删除时间：
          <Time :time="item.dealTime" type="datetime" />
        </div>
        <div style="margin-left:2.5em">删除人：{{item.processor}}</div>
        <div style="margin-left:2.5em">文章级别：{{item.contentLevel}}</div>
      </div>
      <div style="margin-bottom:1em">删除原因：{{item.remark}}</div>
      <ProcessSteps :taskName="item.delTaskName" />
      <Button type="primary" @click="toEditor(item,requestFn)" v-if="recycleable">重新编辑</Button>
    </template>
  </ListFrame>
</template>

<script>
import homeService from '@/api/homePageService';
export default {
  props: {
    laun: {},
    recycleable: {
      default: true
    }
  },
  data() {
    return {
      customedParams: { contentType: '', itemKey: 'processId' }
    };
  },
  methods: {
    getPageFn(params) {
      return homeService.getRecycleList(params);
    },
    toEditor(item, fn) {
      let newRoute = this.$router.resolve({
        path: `/edit4Editor/${item.contentType}`,
        query: {
          parentId: item.id,
          parentProcessId: item.processId
        }
      });
      let subWin = window.open(newRoute.href, item.id);
      subWin.onDBModify = () => {
        typeof fn === 'function' && fn();
        this.$emit('modify');
      };
    }
  },
  beforeMount() {
    this.customedParams.type = this.laun;
  }
};
</script>

<style>
</style>