<template>
  <div>
    <div v-for="(itemLevel,indexLevel) in wordList" :key="indexLevel" class="padding-left">
      <div class="flex content_list">
        <span class="order">{{itemLevel[0].level}}.</span>

        <div>
          <div class="flex flex-wrap">
            <div class="word-item" v-for="(item,index) in itemLevel" :key="index">
              <p class="character">{{item.word}}</p>
              <div class="line"></div>
              <div class="content_list_chunk_type_all">
                <span class="content_list_chunk_type">{{site}}</span>
                <span>{{item[countKey]}}</span>
              </div>
            </div>
          </div>
          <slot name="button_chunk" v-bind:indexLevel="indexLevel"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script >
export default {
  props: {
    wordList: {},
    countKey: {
      type: String
    },
    site: {
      type: String
    }
  }
};
</script>

<style scoped >
.padding-left {
  padding-left: 30px;
}
.content_list {
  margin-top: 30px;  
  font-size: 16px;
}
.flex-wrap {
  flex-wrap: wrap;
}
.word-item {
  margin-right: 16px;
  font-size: 18px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.line {
  background: #3ed67b;
  width: 100%;
  height: 2px;
}
.order {
  font-size: 20px;
  padding: 4px 20px 0 0;
  color: black;
  font-weight: bold;
  height: 100%;
}

.content_list_chunk_type_all {
  margin-top: 5px;
}
.content_list_chunk_type {
  font-size: 14px;
  background: burlywood;
  color: #fff;
  text-align: center;
  padding: 4px;
}
</style>
