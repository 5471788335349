<template>
  <div>
    <div class="title" @click="$emit('clickTitle')">
      主标题：{{ info.title }}
      <br />
      文章标题： {{ info.contentTitle[articInd] && info.contentTitle[articInd].content }}
    </div>
    <div style="clear:both"></div>
    <div class="img-item fl" v-for="(img, ind) in imgs.filter(item => item.key).slice(0, 10)" :key="ind">
      <img class="thumbnail" :src="img.url" />
      <div class="thumbnail-tip">{{ img.content }}</div>
    </div>
    <div style="clear:both"></div>
    <!-- 内容图片 -->
    <div>
      <span class="tag">文章等级：{{ info.contentLevel }}</span>
    </div>
    <div>
      <span class="tag">
        编辑时间：
        <Time v-if="info.workFlowMap.editTime" :time="info.workFlowMap.editTime" type="datetime" />
      </span>
      <span class="tag">
        发布时间：
        <Time v-if="info.workFlowMap.publishTime" :time="info.workFlowMap.publishTime" type="datetime" />
      </span>
      <span class="tag" v-if="info.status == 4 && info.workFlowMap.downShelfTime">
        下架时间：
        <Time :time="info.workFlowMap.downShelfTime" type="datetime" />
      </span>
      <span class="tag" v-if="info.status == 4 && info.workFlowMap.downShelf">下架人：{{ info.workFlowMap.downShelf }}</span>
    </div>
    <div style="display:flex">
      本文篇数：
      <EditorSider style="font-size:10px;" :horizontal="true" :modifiable="false" :activeIndex.sync="articInd"
        :len="info.contentTitle && info.contentTitle.length || 1" />
    </div>
    <div v-if="[0, 1, 2, 3, 6].includes(roleCode)">
      编辑标签：
      <TagsInput v-if="info.tagMap" :tags="info.tagMap[articInd] || []" :editable="false" />
    </div>
    <div v-if="[4, 6].includes(roleCode)">
      插画标签：
      <TagsInput v-if="info.imageTag" :tags="info.imageTag[articInd] || []" :editable="false" />
    </div>
    <div v-if="[5, 6].includes(roleCode)">
      音频标签：
      <TagsInput v-if="info.audioTag" :tags="info.audioTag[articInd] || []" :editable="false" />
    </div>
    <div>
      <span class="tag">编辑：{{ info.workFlowMap[0] }}</span>
      <span class="tag">制图：{{ info.workFlowMap[4] }}</span>
      <span class="tag">配音：{{ info.workFlowMap[5] }}</span>
      <span class="tag" v-if="/^en/.test(info.contentType)">翻译：{{ info.workFlowMap[7] }}</span>
      <span class="tag">终审：{{ info.workFlowMap[6] }}</span>
    </div>
    <div class="flex-middle m-t-xs">
      <Button class="btn" type="warning" @click="preview()">预览</Button>

      <template v-if="![7].includes(roleCode)">
        <Button class="btn" v-if="editable" @click="showEditTag">编辑</Button>
      </template>
      
      <Button class="btn" v-if="onlyRead" @click="toArticleInfo">详情</Button>

      <Button class="btn" v-if="info.contentTitle.length == 1 && info.contentType != 'cnIntensive'" @click="modelShow = true">机构</Button>

      <template v-if="![7].includes(roleCode)">
        <Button class="btn"  @click="esSynConfirm">同步</Button>
      </template>

      <OrganizationForm :info="info" :modelShow="modelShow" @modelShowClose="modelShowClose" />

      <RewriteEntry v-if="rewriteable" :isOnshelf="info.status == 3" :rewrited="!info.rewrite"
        :editorname="info.rewriteName" @rewrite="toRewritePage" @info="toVerify6" />

      <span class="status-span active" v-if="info.status == 3">已上架</span>
      <span class="status-span" v-else>
        已下架
        <span style="padding-left:1em">下架原因：{{ info.remark }}</span>
      </span>
    </div>
  </div>
</template>

<script>
undefined

import OrganizationForm from "./OrganizationForm";
import {esSyn} from "./esSynService"
export default {
  props: {
    info: {},
    editable: {
      default: false
    }
  },
  data() {
    return {
      imgs: [],
      articInd: 0,
      modelShow: false
    };
  },
  computed: {
    roleCode() {
      return this.$store.getters.getRoleCode;
    },
    rewriteable() {
      return ((this.roleCode === 4 &&
        !/^(cn|en)(Intensive)$/.test(this.info.contentType)) ||
        [0, 5].includes(this.roleCode));
    },
    onlyRead() {
      return this.$store.getters.getOnlyRead;
    }
  },
  methods: {
    esSynConfirm () {
        let that = this;
        console.log(that.info)
        this.$Modal.confirm({
            title: '同步',
            content: '<p>请确认该篇文章同步至ES</p>',
            onOk: () => {
                let data = [
                  {
                    "bizId":that.info.id,
                    "contentType":that.info.contentType,
                    "wordFlag":true,
                    "phrasesFlag":true,
                  }
                ]
                esSyn(data).then(res =>{
                  this.$Message.info('已通知后台同步');
                }).catch(err => {
                  that.$Message.warning(err);
                });
            },
            onCancel: () => {
                
            }
        });
    },
    modelShowClose() {
      this.modelShow = false
    },
    preview() {
      this.$emit("clickPreview");
    },
    showEditTag() {
      this.$emit("showEditTag");
    },
    getRewritePageRoute() {
      if (this.roleCode === 0) {
        return this.$router.resolve({
          path: `/edit4Editor/${this.info.contentType}`,
          query: {
            rewriteId: this.info.id,
            parentProcessId: this.info.processId
          }
        });
      }
      if (4 === this.roleCode) {
        return this.$router.resolve({
          name: "imgPage",
          query: {
            rewriteId: this.info.id,
            contentType: this.info.contentType,
            parentProcessId: this.info.processId
          }
        });
      }
      if (5 === this.roleCode) {
        return this.$router.resolve({
          path: "/edit4audio",
          query: {
            rewriteId: this.info.id,
            contentType: this.info.contentType,
            parentProcessId: this.info.processId
          }
        });
      }
      throw new Error("重写错误：不支持的角色 roleCode " + this.roleCode);
    },
    toRewritePage() {
      let routeObj = this.getRewritePageRoute();
      this.$router.push(routeObj.route);
    },
    toArticleInfo() {
      this.$router.push({
        path: "/verify6",
        query: {
          // taskId, bizId,
          bizId: this.info.id,
          contentType: this.info.contentType,
          processId: this.info.processId,
          onlyRead: true
        }
      });
    },
    toVerify6() {
      let routeObj = this.getRewritePageRoute();
      let { path, query } = routeObj.route;
      let desRoute = JSON.stringify({ path, query });
      this.$router.push({
        path: "/verify6",
        query: {
          rewriteId: this.info.id,
          contentType: this.info.contentType,
          parentProcessId: this.info.processId,
          desRoute4rewrite: desRoute
        }
      });
    }
  },
  beforeMount() {
    // 图片每篇文章只显示内容的第一张
    if (/Verse/.test(this.info.contentType)) {
      this.imgs = this.info.backGroundList.slice(0, 1);
    }
    else {
      this.imgs = Array.from(Object.values(this.info.picMap))
        .reduce((pre, arr) => {
          pre.push(arr[0]);
          return pre;
        }, [])
        .filter(ele => ele);
      this.imgs.forEach((ele, ind) => {
        let titleObj = this.info.contentTitle[ind] || {};
        ele.content = titleObj.content;
      });
    }
  },
  components: { OrganizationForm }
};
</script>

<style scoped>
.title {
  font-size: 18px;
  font-weight: bold;
  display: inline-block;
}

.btn {
  min-width: 5.5em;
  margin-right: 1em;
}

.tag:not(:last-child) {
  margin-right: 16px;
}

.img-item {
  width: min-content;
  display: flex;
  flex-direction: column;
  margin-right: 0.5em;
}

.thumbnail {
  height: 6em;
  width: auto;
}

.thumbnail-tip {
  text-align: center;
  background: #eee;
  color: black;
  white-space: nowrap;
}

/* //==// */
</style>