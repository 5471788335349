export default {
    data() {
        return {
            showAudioDrawer: false,
        }
    },
    components: {},
    methods: {
        onAudioFinished() {
            this.showAudioDrawer = false;
        },
        clickUploadAudios(items) {
            // 校验，所选必须同一等级，同一类型
            if (items.length > 1) {
                let fe = items[0];
                if (
                    items.some(
                        ele =>
                            ele.contentType !== fe.contentType ||
                            ele.contentLevel !== fe.contentLevel
                    )
                ) {
                    this.$Message.warning("必须选择同一等级且同一类型的上传配音！");
                    return;
                }
            }
            this.showAudioDrawer = true;
        }
    }
}