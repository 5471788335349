<template>
  <div class="p-l-md p-r-md">
    
    <div v-show="!showSerialItemFlag" class="m-b-xs flex">
      <Input
        v-model="queryBook.name"
        class="m-r-md cond-title"
        style="width: 15em"
        search
        enter-button="查标题"
        placeholder="如：title"
        @on-search="refresh"
      />
      <Button
        class="m-r-md"
        style="width: 6em"
        type="primary"
        @click="SetlzBook"
        title="建立连载"
        >建立连载</Button
      >
      <OrderSelect v-model="orderFlag" class="m-r-md" @input="refreshSelect" />
    </div>
    <div v-show="!showSerialItemFlag">
      <div class="list-lzbook" v-for="(item) in listBook" :key="item.id">
          <p class="list-title">连载书籍名称:{{ item.name }}</p>
          <p class="list-detail">
            <span>创建时间：<Time :time="item.createTime " type="datetime" /></span>
            <span>等级：{{ item.level }}</span>
          </p>
          <div class="action-btn">
            <Button @click="showSerialItem(item)" class="edit-btn" type="primary">编辑</Button>
            <Button @click="delSerialbook(item)" class="edit-btn" type="error">删除</Button>
          </div>
      </div>
      <p class="page-tip" v-if="errorList">{{throwText}}</p>
      <Page
        v-if="totalCount>10"
        :total="totalCount"
        show-total
        show-sizer
        :current="queryBook.pageNo"
        :page-size="queryBook.pageSize"
        :page-size-opts="pageSizeList" 
        @on-change="changePage"
        @on-page-size-change="changePageSize"
      />
    </div>
    <Modal  width="350" class="modal-setlzBook" v-model="modalSet" @on-cancel="cancel">
       <Form ref="formValidate" :model="newlzBook" :rules="ruleValidate" :label-width="0">
        <FormItem label="" prop="name">
          <p class="modal-title">请输入连载书籍名称：</p>
          <Input v-model="newlzBook.name" class="modal-input"></Input>
        </FormItem>
        <FormItem label="" prop="levels">
          <p class="modal-title">请输入连载书籍等级：</p>
          <Input v-model="newlzBook.levels" type="number" @on-keyup="limitNumber()" class="modal-input"></Input>  
        </FormItem>
      </Form>
      <div slot="footer" class="flex" style="justify-content: center;">
        <Button type="primary" style="width: 6em" @click="modalSetSure">确认</Button>
      </div>
    </Modal>
    <SerialBookItem :choiceSerial="choiceSerial" v-if="showSerialItemFlag"></SerialBookItem>  
  </div>
</template>
<script>
import homeService from "@/api/homePageService";
import OrderSelect from "../../list-frame/OrderSelect";
import SerialBookItem from "./SerialBookItem";

export default {
  components: { OrderSelect,SerialBookItem },
  props:{
    showSerialItemFlag: {
      type: Boolean
    }
  },
  data() {
    return {
      modalSet: false,
      contentTitleStr: "",
      orderFlag: "0",
      pageSizeList:[10,20,50],
      ruleValidate: {
        name: [
          { required: true, message: '请输入连载书籍名称', trigger: 'blur' }
        ],
        levels: [
          { required: true, message: '请输入连载书籍等级', trigger: 'blur' }
        ]
      },
      viewConf: {
        showLvl: true,
        showTitle: true,
        showContent: !/^(cn|en)Intensive$/.test(""),
        showShelStatus: true,
        shelfOperateAble: true,
      },
      queryBook:{
        pageSize:10,
        pageNo:1,
        name:'',
        subject:this.$store.getters.getLaun,
        orderType: "timeDesc"
      },
      newlzBook: {
        name: "",
        levels: null,
        subject:this.$store.getters.getLaun
      },
      totalCount: 0,
      totalPage:0,
      listBook: [],
      choiceSerial:{},
      errorList:false,
      throwText:''
    };
  },
  created() {
    console.log("showSerialItemFlag:",this.showSerialItemFlag);
  },
  computed: {

  },
  watch:{
  },
  mounted() {
   
  },
  methods: {
    limitNumber(){
      this.newlzBook.levels = this.newlzBook.levels.replace(/[^\.\d]/g,'');
      this.newlzBook.levels = this.newlzBook.levels.replace('.','');
      this.newlzBook.levels = this.newlzBook.levels.replace('0','');
      this.newlzBook.levels = this.newlzBook.levels.replace('-',''); 
      this.$forceUpdate();
    },
    orderType(val){
      if(val==0){
        return "timeDesc"
      } else if(val==1){
        return "timeAsc"
      } else if(val==2){
        return "levelDesc"
      } else if(val==3){
        return "levelAsc"
      }
    },
    refreshSelect(val){
      this.queryBook.orderType = this.orderType(val);
      this.refresh();
    },
    changePage(val){
      console.log("val:",val);
      this.queryBook.pageNo = val;
      console.log("changePage:",this.queryBook);
      this.refresh();
    },
    changePageSize(pageSizeVal){
      console.log("pageSizeVal:",pageSizeVal);
      this.queryBook.pageSize = pageSizeVal;
      this.refresh();
    },
    delSerialbook(item){
      // this.listBook.splice(index,1);
      console.log("item:",item);
      this.$Modal.confirm({
          title: '是否删除该连载书籍？',
          content: '<p>确认需要删除请点击确认，否则取消。</p>',
          okText: '确认',
          cancelText: '取消',
          onOk: () => {
            this.sureDelSerial(item.id);
          },
      });
    },
    sureDelSerial(id){
      homeService.deleteSeriesBook(id).then((res) => {
        if(res){
          this.$Message.success("删除成功");
          this.refresh();
        }
      }).catch((err) => {
        this.$Message.warning(err);
        throw err;
      })
    },
    SetlzBook() {
      this.modalSet = true;
    },
    showSerialItem(item){
      this.$emit("changeShowSerial", this.showSerialItemFlag);
      this.choiceSerial = item;
    },
    initPage(params) {
      this.$Spin.show();
      homeService.querySeriesBook(params,false).then((res) => {
        this.$Spin.hide();
        if(res.rows && res.rows.length>0){
          this.listBook = res.rows;
          console.log("this.listBook:",this.listBook); 
          this.totalCount = res.totalCount;
          this.totalPage = res.totalPage;
          this.errorList = false;
          this.$forceUpdate();
        } else {
          this.listBook = [];
          this.$forceUpdate();
          this.throwText = "没有连载书籍，请建立连载"
          this.errorList = true;
        }
      }).catch((err) => {
        this.$Spin.hide();
        this.$Message.warning(err);
        this.throwText = "后台请求失败，请联系后台人员！"
        this.errorList = true;
        throw err;
      })
    },
    refresh() {
      this.initPage(this.queryBook);
    },
    onShelf(list) {
      return homeService.onShelf(list);
    },
    offShelf(list, reason) {
      return homeService.offShelf(list, reason);
    },
    cancel(){
      this.handleReset('formValidate');
    },
    handleReset (name) {
      this.$refs[name].resetFields();
    },
    async modalSetSure() {
       // 添加连载书籍
      this.$refs['formValidate'].validate((valid) => {
        if (valid) {
          this.modalSet = false;
          let resAdd =  homeService.addSeriesBook(this.newlzBook).then((res) => {
            if(res){
              this.$Message.success("新增连载书籍成功");
              return true;
            }
          }).catch((err) => {
            this.$Message.warning(err);
            throw err;
          })
          if(resAdd){
            this.refresh();
          }
          this.handleReset('formValidate');
        } 
      })
      
    },
  },
};
</script>
<style lang="scss" scoped>
.modal-setlzBook {
  .ivu-form-item {
    margin-bottom: 10px;
  }
  .ivu-modal-body {
    padding: 30px 20px;
  }
  .modal-title {
    font-size: 14px;
    color: #333333;
    margin-top: 10px;
  }
}
.fengge {
  width: 100%;
  height: 1px;
  margin-top: 20px;
}
.list-lzbook {
  width: 400px;
  margin-bottom: 40px;
}
.list-title {
  font-size: 24px;
  color: #333333;
}
.list-detail {
  font-size: 14px;
  margin-top: 10px;
  color: #666666;
  span {
    margin-right: 20px;
  }
}
.action-btn {
  margin-left: 10px;
  .edit-btn {
    margin: 10px 10px 0 10px;
  }
}
</style>
